body {
    margin: 0;
    background-color: #282D35;
    min-height: 100vh;
    background-position: 100% 200px;
    background-repeat: no-repeat;
    padding: 50px 10px;
}
@media (max-width: 410px) {
    body {
        padding: 10px;
    }
}
.first-react {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}