@font-face {
    font-family: inter;
    src: url(../fonts/Inter-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: inter;
    src: url(../fonts/Inter-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: inter;
    src: url(../fonts/Inter-Bold.ttf);
    font-weight: 700;
}


.businessCard {
    background-color: #1A1B21;
    color: white;
    font-family: inter;
    font-size: 13px;
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
}

.businessCard__details {
    padding: 35px 25px;
}

.businessCard__detailsName {
    margin-top: 0;
}

.businessCard__footList {
    margin: 0;
}

.businessCard__foot {
    background-color: #161619;
    padding: 20px;
}

.businessCard__footList {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}

.businessCard__detailsMain {
    margin-bottom: 40px;
}
.businessCard__detailsMain--margin_sm {
    margin-bottom: 20px;
}

.businessCard__detailsMainTitle {
    margin: 10px 0;
    font-weight: 700;
    font-size: 16px;
}

.businessCard__detailsMainContent, .businessCard__detailsMainListItem {
    font-weight: 400;
    font-size: 11px;
    color: #DCDCDC;
    line-height: 1.5em;
}

.businessCard__detailsMainList {
    padding-left: 15px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    flex-direction: column;
}
.businessCard__detailsMainList--icons {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.businessCard__detailsMainListItem {
    margin: 4px;
    justify-content: space-evenly;
}

.businessCard__detailsMainListItem--icon {
    margin: 5px auto;
    padding: 5px;
}

.businessCard__detailsMainListItemLabel {
    display: none;
}
.businessCard__detailsMainListItem::marker {
    color: #f7df1e;
    font-size: 15px;
}

.businessCard__detailsIcon {
    font-size: 40px;
}

.businessCard__detailsName {
    text-align: center;
    font-family: inter;
}

.businessCard__detailsPosition {
    text-align: center;
    font-family: inter;
}

.businessCard__detailsWebsite {
    text-align: center;
    font-family: inter;
    color: grey;
    text-align: center;
    display: block;
}

.businessCard__detailsButtons {
    display: flex;
    justify-content: center;
}

.businessCard__detailsButton {
    margin: 15px;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    width: 115px;
    align-items: center;
    display: inline-flex;
    font-family: inter;
    border-radius: 6px;
    justify-content: center;
}

.businessCard__detailsButton--white {
    background-color: white;
    color: #374151;
}

.businessCard__detailsButton--lightblue {
    background-color: #5093E2;
    color: white;
}

.businessCard__footLink {
    text-decoration: none;
    color: #918E9B;
    font-size: 20px;
}

.businessCard__detailsButton .icon-linkedin:before {
    color: white;
}

.businessCard__detailsButton i {
    margin-right: 8px;
}