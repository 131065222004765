@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?4dp4ys');
    src:  url('../fonts/icomoon.eot?4dp4ys#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?4dp4ys') format('truetype'),
      url('../fonts/icomoon.woff?4dp4ys') format('woff'),
      url('../fonts/icomoon.svg?4dp4ys#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-envelope:before {
    content: "\e900";
  }
  .icon-mail:before {
    content: "\e900";
  }
  .icon-email:before {
    content: "\e900";
  }
  .icon-contact:before {
    content: "\e900";
  }
  .icon-profile:before {
    content: "\e923";
  }
  .icon-location:before {
    content: "\e947";
  }
  .icon-earth:before {
    content: "\e9ca";
  }
  .icon-twitter:before {
    content: "\ea96";
  }
  .icon-linkedin1:before {
    content: "\eac9";
  }
  .icon-wordpress:before {
    content: "\e901";
    color: #918E9B;
  }
  .icon-linkedin:before {
    content: "\e902";
    color: #0077b5;
  }
  .icon-github:before {
    content: "\e903";
  }
  .icon-unity:before {
    content: "\e904";
  }
  .icon-csharp:before {
    content: "\e905";
    color: #239120;
  }
  .icon-docker:before {
    content: "\e906";
    color: #1488c6;
  }
  .icon-node-dot-js:before {
    content: "\e907";
    color: #393;
  }
  .icon-django:before {
    content: "\e908";
  }
  .icon-react:before {
    content: "\e909";
    color: #61dafb;
  }
  .icon-css3:before {
    content: "\e90a";
    color: #1572b6;
  }
  .icon-html5:before {
    content: "\e90b";
    color: #e34f26;
  }
  .icon-javascript:before {
    content: "\e90c";
    color: #f7df1e;
  }
  .icon-laravel:before {
    content: "\e90d";
    color: #ff2d20;
  }
  .icon-php:before {
    content: "\e90e";
    color: #777bb4;
  }
  .icon-python:before {
    content: "\e90f";
    color: #3776ab;
  }
  